import { Popover, Transition } from "@headlessui/react"
import clsx from "clsx"
import { useTranslation } from "next-i18next"
import Link from "next/link"
import { useRouter } from "next/router"
import { Fragment, useState } from "react"
import useAuth from "../../hooks/useAuth"
import { Button } from "../ui/button"
import { DarkModeLogo } from "../ui/DarkModeLogo"
import { Container } from "./components/Container"
import { NavLink } from "./components/NavLink"

function MobileNavLink({
  href,
  children
}: {
  href: string
  children: JSX.Element | JSX.Element[] | string
}) {
  return (
    <Popover.Button as={Link} href={href} className="block w-full p-2">
      {children}
    </Popover.Button>
  )
}

function MobileNavIcon({ open }: { open: boolean }) {
  return (
    <svg
      aria-hidden="true"
      className="h-3.5 w-3.5 overflow-visible stroke-slate-700"
      fill="none"
      strokeWidth={2}
      strokeLinecap="round"
    >
      <path
        d="M0 1H14M0 7H14M0 13H14"
        className={clsx(
          "origin-center transition",
          open && "scale-90 opacity-0"
        )}
      />
      <path
        d="M2 2L12 12M12 2L2 12"
        className={clsx(
          "origin-center transition",
          !open && "scale-90 opacity-0"
        )}
      />
    </svg>
  )
}

function MobileNavigation({ loggedIn = false }: { loggedIn?: boolean }) {
  return (
    <Popover>
      <Popover.Button
        className="relative z-10 flex h-8 w-8 items-center justify-center [&:not(:focus-visible)]:focus:outline-none"
        aria-label="Toggle Navigation"
      >
        {({ open }) => <MobileNavIcon open={open} />}
      </Popover.Button>
      <Transition.Root>
        <Transition.Child
          as={Fragment}
          enter="duration-150 ease-out"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="duration-150 ease-in"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <Popover.Overlay className="fixed inset-0 bg-slate-300/50" />
        </Transition.Child>
        <Transition.Child
          as={Fragment}
          enter="duration-150 ease-out"
          enterFrom="opacity-0 scale-95"
          enterTo="opacity-100 scale-100"
          leave="duration-100 ease-in"
          leaveFrom="opacity-100 scale-100"
          leaveTo="opacity-0 scale-95"
        >
          <Popover.Panel
            as="div"
            className="absolute inset-x-0 top-full mt-4 flex origin-top flex-col rounded-2xl bg-white p-4 text-lg tracking-tight text-slate-900 shadow-xl ring-1 ring-slate-900/5"
          >
            <MobileNavLink href="/blog">Blog</MobileNavLink>
            {loggedIn ? (
              <MobileNavLink href="/bookshelf">Go to Dashboard →</MobileNavLink>
            ) : (
              <MobileNavLink href="/auth/sign-in">Sign in</MobileNavLink>
            )}
          </Popover.Panel>
        </Transition.Child>
      </Transition.Root>
    </Popover>
  )
}

export function Header({ loggedIn = false }: { loggedIn?: boolean }) {
  const router = useRouter()
  const { t, i18n } = useTranslation()
  const [language, setLanguage] = useState(i18n.language)
  const handleLanguageChange = async (
    event: React.ChangeEvent<HTMLSelectElement>
  ) => {
    const newLanguage = event.target.value
    await i18n.changeLanguage(newLanguage)
    localStorage.setItem("preferredLanguage", newLanguage)
    setLanguage(newLanguage)
    const { pathname, asPath, query } = router
    router.push({ pathname, query }, asPath, { locale: newLanguage })
  }

  return (
    <header className="bg-slate-900 shadow-sm">
      <Container>
        <nav className="relative z-50 flex justify-between items-center py-4">
          <div className="flex items-center md:gap-x-12">
            <Link href="/" aria-label="Home">
              <DarkModeLogo className="h-8 w-auto" />
            </Link>
            <div className="hidden md:flex md:gap-x-6">
              {/* Add navigation links if needed */}
              <NavLink href="/#features">{t("Features")}</NavLink>
              <NavLink href="/#pricing">{t("Pricing")}</NavLink>
              <NavLink href="/#faq">{t("FAQ")}</NavLink>
            </div>
          </div>
          <div className="flex items-center gap-x-5 md:gap-x-8">
            {/* Show Sign Up link only on larger screens */}
            <div className="hidden md:flex items-center gap-x-5 md:gap-x-8">
              <NavLink href="/auth/sign-up">{t("Sign up")}</NavLink>
              <NavLink href="/auth/sign-in">{t("Sign in")}</NavLink>
            </div>
            {/* Dark Mode Toggle */}
            {/* {!notShowingDarkModeTogglePath.includes(router.pathname) && (
              <ModeToggle />
            )} */}
            {/* Mobile Navigation */}
            <div className="-mr-1 md:hidden">
              <MobileNavigation loggedIn={loggedIn} />
            </div>
            {/* Language Selector */}
            <select
              value={language}
              onChange={handleLanguageChange}
              className="cursor-pointer rounded bg-slate-900 text-sm border-none focus:ring-blue-500 focus:border-blue-500 text-slate-50"
            >
              <option value="en">English</option>
              <option value="jp">日本語</option>
            </select>
            {/* Dashboard Button for logged in users */}
            {loggedIn && (
              <Button
                onClick={() => {
                  router.push("/dashboard")
                }}
                variant="solidBlue"
              >
                {t("Go to Dashboard")}
              </Button>
            )}
          </div>
        </nav>
      </Container>
    </header>
  )
}
