import Link from "next/link"

export function NavLink({
  href,
  children
}: {
  href: string
  children: string
}) {
  return (
    <Link
      href={href}
      className="inline-block rounded-lg py-1 px-2 text-sm text-slate-50 hover:bg-slate-300 hover:text-slate-900 dark:text-slate-500"
    >
      {children}
    </Link>
  )
}
